<template>
  <div id="portal-settings">
    <!-- settings -->

    <!-- action bar -->

    <ActionBar v-model="tab" :tabs="tabs" @save="saveSettings" />

    <!-- ... -->

    <BaseScrollbar height="calc(100vh - 125px)">
      <div class="row q-pb-xl">
        <ValidationObserver ref="portal">
          <!-- general settings -->

          <GeneralSettings
            ref="GENERAL_SETTINGS"
            :portal="portal"
            :mode="mode"
            :name.sync="name"
            :description.sync="description"
            :workflow.sync="workflow"
            :superuser.sync="superuser"
            :workflow-list="workflowList"
            :user-list="userList"
            :is-active="tab === 'GENERAL_SETTINGS'"
            @click="tab = 'GENERAL_SETTINGS'"
          />

          <!-- ... -->

          <BaseSeparator has-left-inset />

          <!-- authentication settings -->

          <AuthenticationSettings
            ref="AUTHENTICATION_SETTINGS"
            feature="Portal Authentication"
            :authentication.sync="authentication"
            :form-list="formList"
            :is-active="tab === 'AUTHENTICATION_SETTINGS'"
            @click="tab = 'AUTHENTICATION_SETTINGS'"
          />

          <!-- ... -->

          <BaseSeparator has-left-inset />

          <!-- notification settings -->

          <Notification
            ref="NOTIFICATIONS_SETTINGS"
            feature="Portal Authentication"
            :authentication.sync="authentication"
            :form-list="formList"
            :is-active="tab === 'NOTIFICATIONS_SETTINGS'"
            @click="tab = 'NOTIFICATIONS_SETTINGS'"
          />

          <!-- ... -->

          <!-- <WorkInProgress
            v-if="tab === 'NOTIFICATIONS_SETTINGS'"
            feature="Portal Notifications"
          /> -->
          <WorkInProgress
            v-if="tab === 'WELCOME_PAGE_SETTINGS'"
            feature="Portal Welcome Page"
          />
          <WorkInProgress
            v-if="tab === 'THANK_YOU_PAGE_SETTINGS'"
            feature="Thank you Page"
          />
          <WorkInProgress
            v-if="tab === 'THANK_YOU_PAGE_SETTINGS'"
            feature="Thank you Page"
          />
          <WorkInProgress
            v-if="tab === 'HEADER_AND_FOOTER'"
            feature="Header & Footer"
          />
        </ValidationObserver>

        <!-- ... -->
      </div>
    </BaseScrollbar>

    <!-- ... -->
  </div>
</template>

<script>
import ActionBar from "../components/ActionBar.vue";
import { ValidationObserver } from "vee-validate";
import GeneralSettings from "./components/general-settings/GeneralSettings.vue";
import AuthenticationSettings from "./components/general-settings/AuthenticationSettings.vue";
import { cloneDeep } from "lodash-es";
import WorkInProgress from "@/components/common/state/WorkInProgress.vue";
import Notification from "./components/general-settings/NotificationSettings.vue";

export default {
  name: "PortalSettings",

  components: {
    ActionBar,
    ValidationObserver,
    GeneralSettings,
    AuthenticationSettings,
    WorkInProgress,
    Notification,
  },

  props: {
    portal: {
      type: Object,
      required: true,
    },

    workflowList: {
      type: Array,
      default: () => [],
    },

    userList: {
      type: Array,
      default: () => [],
    },

    formList: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      mode: "NEW",
      tab: "GENERAL_SETTINGS",
      tabs: [
        {
          id: this.$nano.id(),
          label: "general",
          value: "GENERAL_SETTINGS",
        },
        {
          id: this.$nano.id(),
          label: "authentication",
          value: "AUTHENTICATION_SETTINGS",
        },
        {
          id: this.$nano.id(),
          label: "notifications",
          value: "NOTIFICATIONS_SETTINGS",
        },
        // {
        //   id: this.$nano.id(),
        //   label: "welcome page",
        //   value: "WELCOME_PAGE_SETTINGS",
        // },
        // {
        //   id: this.$nano.id(),
        //   label: "thank you page",
        //   value: "THANK_YOU_PAGE_SETTINGS",
        // },
        // {
        //   id: this.$nano.id(),
        //   label: "header & footer",
        //   value: "HEADER_AND_FOOTER",
        // },
      ],

      // general settings

      name: "",
      description: "",
      workflow: 0,
      superuser: [],

      // specific settings
      authentication: {
        loginType: "",
        formId: 0,
        usernameField: [],
        passwordField: "",
        firstnameField: "",
        socialLogin: [],
        passwordTypes: "OTP",
        notification: false,
      },
    };
  },

  watch: {
    tab() {
      this.$refs[this.tab].$el.scrollIntoView({ behavior: "smooth" });
    },

    portal: {
      immediate: true,
      deep: true,
      handler() {
        const portalSettings = cloneDeep(this.portal.settings);

        this.name = portalSettings.general.name;
        this.description = portalSettings.general.description;
        this.workflow = portalSettings.general.workflow;
        this.superuser = portalSettings.general.superuser;
        this.authentication = portalSettings.specific.authentication;
        if (typeof this.authentication.usernameField === "string") {
          this.authentication.usernameField = [
            this.authentication.usernameField,
          ];
        }
      },
    },
  },

  methods: {
    async saveSettings() {
      const isValid = await this.$refs.portal.validate();

      if (!isValid) {
        return;
      }
      const portalSettings = cloneDeep(this.portal.settings);

      portalSettings.general.name = this.name;
      portalSettings.general.description = this.description;
      portalSettings.general.workflow = this.workflow;
      portalSettings.general.superuser = this.superuser;
      portalSettings.specific.authentication = this.authentication;

      this.$emit("update", portalSettings);
    },
  },
};
</script>

<style lang="scss" scoped></style>
