<template>
  <div id="portal-builder-wrapper">
    <ItemBuilder @close="closeBuilder">
      <!-- header -->

      <template #title> Portal {{ _tab }} </template>

      <template #description>
        {{ _description }}
      </template>

      <template #header>
        <div class="row items-center justify-center">
          <Tabs v-model="tab" :tabs="tabs" is-full-width class="tabs" />
        </div>
      </template>

      <template #actions>
        <BaseActionButton
          icon="eva-question-mark"
          color="secondary"
          class="q-mr-sm"
          @click="showQuickHelp"
        />
      </template>

      <!-- ... -->

      <!-- content -->

      <!-- <WorkInProgress v-if="tab === 'BUILDER'" feature="Portal Builder" /> -->
      <PortalBuilder
        v-show="tab === 'BUILDER'"
        :portal="portal"
        @save="createPortal"
      />

      <PortalSettings
        v-if="tab === 'SETTINGS'"
        :portal="portal"
        :workflow-list="workflowList"
        :user-list="userList"
        :form-list="masterFormList"
        @update="updateSettings"
      />

      <PortalPublish
        v-if="tab === 'PUBLISH'"
        :portal-settings="portal.settings"
        @update="updateSettings"
      />

      <!-- ... -->

      <!-- quick help -->

      <QuickHelp v-model="quickHelp" />

      <!-- ... -->
    </ItemBuilder>
  </div>
</template>

<script>
import ItemBuilder from "@/components/common/item-builder/ItemBuilder.vue";
import Tabs from "@/components/common/tabs/Tabs.vue";
import QuickHelp from "@/components/common/QuickHelp.vue";
import { capitalize, lowerCase } from "lodash-es";
// import WorkInProgress from "@/components/common/state/WorkInProgress.vue";
import PortalBuilder from "./portal-builder/PortalBuilder.vue";
import PortalSettings from "./portal-settings/PortalSettings.vue";
import PortalPublish from "./portal-publish/PortalPublish.vue";
import { portal, user, workflow, form } from "@/api/factory.js";

export default {
  name: "PortalBuilderWrapper",

  components: {
    ItemBuilder,
    Tabs,
    QuickHelp,
    // WorkInProgress,
    PortalSettings,
    PortalPublish,
    PortalBuilder,
  },

  props: {
    portalId: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      quickHelp: false,
      tab: "SETTINGS",
      tabs: [
        {
          id: this.$nano.id(),
          label: "Builder",
          value: "BUILDER",
          description: "Create custom portals",
        },
        {
          id: this.$nano.id(),
          label: "Settings",
          value: "SETTINGS",
          description: "Customize and manage your portal settings",
        },
        {
          id: this.$nano.id(),
          label: "Publish",
          value: "PUBLISH",
          description: "Publish and deploy your portal in action",
        },
      ],
      portal: {
        uid: this.$nano.id(),

        settings: {
          general: {
            name: "Portal - " + this.$day.newDateTime(),
            description: "",
            workflow: 0,
            superuser: [],
          },

          specific: {
            authentication: {
              loginType: "",
              formId: 0,
              usernameField: "",
              passwordField: "",
              firstnameField: "",
              socialLogin: [],
              passwordTypes: "OTP",
              notification: false,
            },
          },

          publish: {
            publishOption: "DRAFT",
            publishSchedule: "",
            unpublishSchedule: "",
          },
        },
        isDeleted: false,
      },
      workflowList: [],
      userList: [],
      watchPortalSave: null,
      masterFormList: [],
    };
  },

  computed: {
    _tab() {
      return capitalize(lowerCase(this.tab));
    },

    _description() {
      let currentTab = this.tabs.filter((item) => item.value === this.tab);
      return currentTab[0].description;
    },
  },

  watch: {
    portalId: {
      immediate: true,
      handler() {
        localStorage.setItem("tId", this.$store.state.session.tenantId);
        if (this.portalId) {
          this.mode = "EDIT";
          this.getPortal();
          localStorage.setItem("portalId", this.portalId);
        } else {
          localStorage.setItem("portalId", 0);
        }
      },
    },
  },

  created() {
    this.getUsers();
    this.getWorkflows();
    this.getMasterForms();
  },

  methods: {
    showQuickHelp() {
      this.quickHelp = true;
    },

    closeBuilder() {
      this.$router.back();
    },

    updateSettings(settings) {
      this.portal.settings = settings;
      this.tab = "BUILDER";
    },

    checkPortalSave() {
      if (localStorage.getItem("portalSavedSuccessfully")) {
        if (localStorage.getItem("portalSavedSuccessfully") === "true") {
          if (this.portalId) {
            this.$alert.success(`${this.portal.settings.general.name} updated`);
          } else {
            this.$alert.success(`${this.portal.settings.general.name} created`);
          }
          this.closeBuilder();
        } else {
          this.$alert.error("Error in save portal");
        }
        this.$store.commit("hideLoadingBar");
        localStorage.removeItem("portalSavedSuccessfully");
        clearInterval(this.watchPortalSave);
      }
    },

    /* api functions */

    async getUsers() {
      this.userList = [];
      const { error, payload } = await user.getUserList();

      if (error) {
        this.$alert.error("Error fetching users");
        return;
      }

      if (payload) {
        payload.map((user) => {
          this.userList.push({
            id: this.$nano.id(),
            label: user.value,
            value: String(user.id),
          });
        });
      }
    },

    async getWorkflows() {
      this.workflowList = [];
      const { error, payload } = await workflow.getWorkflows({
        mode: "BROWSE",
        sortBy: { criteria: "", order: "DESC" },
        groupBy: "",
        filterBy: [
          {
            filters: [
              {
                criteria: "flowstatus",
                condition: "IS_EQUALS_TO",
                value: "PUBLISHED",
                dataType: "",
              },
            ],
            groupCondition: "",
          },
        ],
        itemsPerPage: 0,
        currentPage: 0,
        hasSecurity: false,
      });

      if (error) {
        this.$alert.error("Error fetching workflows");
        return;
      }

      if (payload) {
        if (payload.data.length) {
          payload.data[0].value.forEach((workflow) => {
            this.workflowList.push({
              id: this.$nano.id(),
              label: workflow.name,
              value: workflow.id,
            });
          });
        }
      }
    },

    async getMasterForms() {
      const { error, payload } = await form.getForms({
        mode: "BROWSE",
        sortBy: { criteria: "", order: "DESC" },
        groupBy: "",
        filterBy: [
          {
            filters: [
              {
                criteria: "type",
                condition: "IS_EQUALS_TO",
                value: "MASTER",
                dataType: "",
              },
              {
                criteria: "publishOption",
                condition: "IS_EQUALS_TO",
                value: "PUBLISHED",
                dataType: "",
              },
            ],
            groupCondition: "",
          },
        ],
        itemsPerPage: 500,
        currentPage: 1,
        hasSecurity: false,
      });

      if (error) {
        this.$alert.error("Error fetching master form list");
        return;
      }

      this.masterFormList = [];
      const { data } = payload;
      if (data.length) {
        this.masterFormList = data[0].value.map((form) => ({
          id: this.$nano.id(),
          label: form.name,
          value: form.id,
        }));
      }
    },

    async createPortal() {
      this.$store.commit("showLoadingBar");
      let input = {
        name: this.portal.settings.general.name,
        description: this.portal.settings.general.description,
        workflowId: this.portal.settings.general.workflow,
        superUser: this.portal.settings.general.superuser.toString(),
        settingsJson: JSON.stringify(this.portal.settings.specific),
      };
      if (this.portalId) {
        const { error } = await portal.updatePortal(this.portalId, input);

        if (error) {
          if (error !== "error updating portal") {
            this.$alert.warning(error);
          } else {
            this.$alert.error(error);
          }
          this.$store.commit("hideLoadingBar");
          return;
        }
        let origin = location.origin;
        if (
          origin === "https://demo.ezofis.com" ||
          origin === "http://52.172.32.88"
        ) {
          localStorage.setItem("savePortal", true);
          this.watchPortalSave = setInterval(
            () => this.checkPortalSave(),
            5000
          );
        } else {
          this.$alert.success(`${this.portal.settings.general.name} updated`);
          this.closeBuilder();
        }
      } else {
        const { payload, error } = await portal.createPortal(input);

        if (error) {
          if (error !== "error creating portal") {
            this.$alert.warning(error);
          } else {
            this.$alert.error(error);
          }
          this.$store.commit("hideLoadingBar");
          return;
        }
        let origin = location.origin;
        if (
          origin === "https://demo.ezofis.com" ||
          origin === "http://52.172.32.88"
        ) {
          localStorage.setItem("portalId", payload.id);
          localStorage.setItem("savePortal", true);
          this.watchPortalSave = setInterval(
            () => this.checkPortalSave(),
            5000
          );
        } else {
          this.$alert.success(
            `${this.portal.settings.general.name} portal created`
          );
          this.closeBuilder();
        }
      }
    },

    async getPortal() {
      this.$store.commit("showLoadingBar");
      const { payload, error } = await portal.getPortal(this.portalId);

      this.$store.commit("hideLoadingBar");

      if (error) {
        this.$alert.error(error);
        return;
      }

      if (payload) {
        console.log(payload.settingsJson);
        this.portal.settings.general.name = payload.name;
        this.portal.settings.general.description = payload.description;
        this.portal.settings.general.workflow = payload.workflowId;
        this.portal.settings.general.superuser = payload.superUser
          ? payload.superUser.split(",")
          : [];
        this.portal.settings.specific = payload.settingsJson
          ? JSON.parse(payload.settingsJson)
          : {
              authentication: {
                loginType: "",
                formId: 0,
                usernameField: "",
                passwordField: "",
                firstnameField: "",
                socialLogin: [],
                passwordTypes: "OTP",
                notification: false,
              },
            };
        if (!this.portal.settings.specific.authentication.passwordTypes) {
          this.portal.settings.specific.authentication.passwordTypes = "OTP";
        }
        if (!this.portal.settings.specific.authentication.notification) {
          this.portal.settings.specific.authentication.notification = false;
        }
      }
    },

    /* ... */
  },
};
</script>

<style lang="scss" scoped>
#portal-builder-wrapper {
  height: 100vh;

  .tabs {
    width: 240px;
    border-bottom: 1px solid var(--divider-color);
  }
}
</style>
